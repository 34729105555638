body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  background-color: #000000;
  background: #000000;
}

.text-shadow {
  text-shadow: -2px 2px 2px #000, 2px 2px 2px #000, 2px -2px 2px #000, -2px -2px 2px #000;
}

.arial-lighter {
  font-family: arial;
  font-weight: lighter;
}

.highlight {
  color: rgb(74, 173, 231) !important;
}

.not-highlighted {
  color: #5e5a5a !important;
}

.title {
  color: #dddd4e;
}

.selectable-text {
  font-size: 3.25em;
  margin-bottom: 10px;
  margin-top: 0px;
  cursor: pointer;
}

/* TODO: see if there is a way to make these classes more flexible */

.fadeout-animation {
  opacity: 0;
  animation: fadeOut 3s;
  animation-iteration-count: 1;
  background: #000000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9000;
  pointer-events: none;
}

.fadein-animation {
  opacity: 1;
  animation: fadeIn 3s;
  animation-iteration-count: 1;
  background: #000000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9000;
  pointer-events: none;
}

.fadeout-modal {
  animation: fadeOut .45s forwards;
}

.fadein-modal {
  animation: fadeIn .75s forwards;
}

.fadeout-intro {
  position: absolute;
  background: #000000;
  width: 100%;
  height: 100%;
  z-index: 9000;
  animation: fadeOut ease-in 2.9s forwards;
}

.fadein-sony {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn ease 1s forwards;
}

.sony {
  color:white;
  font-size: 48px;
  font-family: arial;
  z-index: 100;
  pointer-events: none;

  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInOut ease-in-out 8s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    pointer-events: auto;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}
