.about-me-bg {
  background-image: radial-gradient(farthest-corner at 40% 50%, #000 5%, #150c22 15%, #5a4878 100%);
  z-index: 0 !important; 
  position: absolute !important;
}

.about-me-section {
  position: absolute;
  right: 20%;
  padding-right: 24px;
  max-width: 25vw;
  top: 25%;
  max-height: 40vh;
  width: 40vw;
  display: flex;
  align-items: center;
  word-break: break-word;
  justify-content: space-around;
}

.about-me-wrapper {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
}

.about-me-section > .about-me-section-content {
  min-width: 15vw;
  max-width: 20vw;
  text-align: center;
}

.about-me-title {
  font-size: 3.25em;
  margin-bottom: .5em;
}

.blurred-div {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttons>button {
  background: transparent;
  border: none;
}

.buttons>button:hover {
  transform: scale(1.09);
  transition: transform 1.05s ease-in-out;
  cursor: pointer;
}

.date-things {
  color: white;
  font-size: 48px;
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

.up-arrow {
  height: 30px;
}

.down-arrow {
  transform: scaleY(-1);
  margin-top: 8px;
  height: 30px;
}

.about-me-header {
  font-size: 3.25em;
  margin-top: 0em;
  margin-bottom: 0.25em;
}