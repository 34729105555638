.back-button:hover {
  transform: scale(1.09);
  transition:transform 1.05s ease-in-out;
  cursor: pointer;
}

.back-button {
  z-index: 2;
  position: absolute;
  bottom: 0%;
  left: 50% ;
  animation: 3.25s ease-out 0s 1 fadeIn;
}

.back-button > span {
  font-size: 48px;
  color: white;
  align-items: center;
  display: flex;
}

img {
  width: 84px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}