.ps2-header {
    position: absolute;
    margin-left: 20px;
    color: white;
    font-size: 48px;
}

.mem-card-header {
    float: right;
    margin-right: 20px;
    color: rgb(221, 221, 78);
    display: flex;
    align-items: center;
    font-size: 48px;
    position: relative;
}

.mem-card-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(145deg, rgb(126, 122, 122) 0%, rgba(0, 0, 0, 1) 116%);
}