.modal-background {
  width: 100%;
  height: 100%;
}

.object-selector-canvas {
  z-index: 0;
  position: absolute;
}

.modal-canvas > div {
  z-index: 2;
  position: absolute;
}

.loading-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
  z-index: 12345;
  font-size: 64px;
  color: #dfdbdb;
  font-family: arial;
  font-weight: lighter;
  pointer-events: none;
}

.memory-card-title {
  font-size: 48px;
  color: #dfdbdb;
  position: absolute;
  margin-left: 24px;
  pointer-events: None 
}

.memory-card-highlighted {
  float: right;
  margin-right: 24px;
  pointer-events: None;
  font-size: 48px
}

.freespace { 
  margin-top: 0px;
  font-size: 24px;
}

.ps2 {
  font-size: 32px;
}